// 引入
@import "./_include/_include.sass";

.seese-mainmenu ul li:hover ul {
  padding-top: 20px;
  padding-bottom: 20px; }


.seese-mainmenu ul li ul li {
  &:last-of-type {
    padding-bottom: 0; } }

.seese-topright {
  display: none; }

.seese-header {
  .container {
    position: static;
    &>.row {
      display: flex;
      position: static; } }
  // logo
  .seese-logo.site-logo {
    min-width: 300px;
    max-width: 300px;
    width: 300px;
    margin: 0;
    padding-top: 25px;
    padding-bottom: 20px;
    @include desk {
      width: 110px;
      max-width: 110px;
      min-width: 110px; }
    @include tablet {
      margin: 0 auto;
      padding: 15px 0; }
    a {
      display: flex;
      align-items: center;
      font-size: 18px;
      letter-spacing: 1.35px;
      @include desk {
        font-size: 0;
        line-height: 0; }
      @include tablet {
        display: block; }
      img {
        display: block;
        margin-right: 6px;
        @include size(100px,auto);
        @include tablet {
          margin: 0 auto;
          @include size(70px,auto); } } } }

  .seese-mainmenu {
    width: calc(100% - 300px);
    position: static;
    @include clearfix;
    @include desk {
      width: calc(100% - 110px); } }

  #seese-menu {
    float: right;
    padding-top: 70px;
    position: static;
    li {
      margin: 0; }
    &>li {
      padding: 0 0 16px;
      margin-left: 40px;
      @include desk(1100px) {
        margin-left: 20px; }
      &:first-of-type {
        margin-left: 0; }
      &.right-icon {
        display: none; }
      &>a {
        padding-bottom: 5px;
        border-bottom: solid 2px transparent;
        @include font {
          @include clr($gray); } }
      &:hover {
        &>a {
          border-bottom-color: $green;
          font-weight: bold;
          @include clr($blue); } }
      // 第二層
      ul {
        padding: 0;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
        background-color: rgba(#FFFFFF,.9);
        li {
          padding: 0;
          a {
            padding: 10px 0;
            text-align: center;
            @include font(16px) {
              @include clr($gray);
              font-weight: bold; }
            &:hover {
              @include clr($blue); } } } }
      // 特殊選單
      &.figure-links {
        position: static;
        ul {
          display: flex;
          padding: 0 20vw;
          left: 0;
          transform: none;
          width: 100%;
          @include max(1280px) {
            padding: 0 15vw; }
          li {
            width: calc(100% / 5);
            padding-top: 0; } } }
      .figure-link {
        &>a {
          padding-top: 0;
          img,svg {
            display: block;
            margin: 0 auto;
            max-width: 130px;
            path,rect {
              fill: $gray; } }
          &:hover {
            img,svg {
              path,rect {
                fill: $blue; } } } } } } } }


.top-links-bar {
  position: absolute;
  top: 30px;
  right: 50%;
  transform: translateX(50%);
  @include container;
  @include clearfix;
  @include tablet {
    display: none; }
  ul {
    display: flex;
    float: right;
    li {
      margin-left: 12px;
      padding-left: 12px;
      border-left: solid 1px #E0E0E0;
      &:first-of-type {
        border-left: none;
        padding-left: 0;
        margin-left: 0; } }
    a {
      img,svg {
        display: block;
        @include size(25px);
        rect {
          fill: $gray; } }
      &:hover {
        img,svg {
          rect {
            fill: $blue; } } } } } }

.seese-menubar {
  .container {
    &+.container {
      width: 100%; } } }

#seese-mobilemenu {
  .top-links-bar {
    display: block;
    position: static;
    transform: none;
    padding-top: 20px;
    padding-bottom: 20px;
    ul {
      float: none;
      &>li {
        margin-left: 12px;
        padding-left: 12px;
        &:first-of-type {
          padding-left: 0;
          margin-left: 0; }
        &>a {
          padding: 0; } } } }
  .slicknav_menu {
    .slicknav_btn {
      @include tablet {
        margin-bottom: 0;
        right: 30px; } } }
  .slicknav_nav.slicknav_nav {
    padding: 10px 0 0;
    margin: 0;
    &>li>a,&>li>a>a {
      font-size: 16px;
      @include phone {
        font-size: 14px; } }
    &>li {
      margin: 0;
      border: none;
      &>a {
        padding: 10px 30px; }
      &.slicknav_open>a {
        &>a,.slicknav_arrow:before {
          font-weight: bold;
          @include clr($blue); } }
      // 手機第二層
      ul {
        background-color: $blue;
        padding: 10px 0;
        li {
          background: none;
          margin: 0;
          border: none;
          &>a {
            padding: 7px 30px;
            font-size: 14px;
            @include clr(#fff);
            @include phone {
              font-size: 12px; } } } } }

    .figure-link {
      a {
        display: flex;
        align-items: center;
        img,svg {
          margin-right: 10px;
          @include size(50px);
          path,rect {
            fill: #fff; } } } } } }


#seese-mobilemenu .slicknav_nav li a span.slicknav_arrow:before {
  content: "+"; }

#seese-mobilemenu .slicknav_nav li.slicknav_open>a>span.slicknav_arrow:before {
  content: "-"; }
