// 引入
@import "./_include/_include.sass";

* {
  vertical-align: middle;
  font-family: 'Microsoft JhengHei'; }

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  font-family: 'Microsoft JhengHei'; }

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none; } }

// bg
[data-bg] {
  display: block;
  @include size(100%,auto);
  background-size: cover;
  background-repeat: no-repeat; }

.container {
  @include container; }

.kc-row-container.kc-container {
  @include clearfix; }

body {
  animation: fade .6s 1s both; }
