// 引入
@import "./_include/_include.sass";


.seese-footer {
  background-color: $blue;
  padding: 0 0 25px;
  @include tablet {
    padding-bottom: 10px; }
  .container {
    margin: 0;
    max-width: 100%;
    width: 100%; }

  p.copyright {
    display: flex;
    align-items: center;
    padding: 0;
    @include font(14px,1px,#fff);
    @include tablet {
      padding-top: 10px;
      justify-content: center;
      @include font(12px,1px,#fff); }
    a {
      display: block;
      @include font(14px,1px,#fff);
      margin-left: 10px; } }

  .map {
    position: relative;
    padding-bottom: 23%;
    @include tablet {
      padding-bottom: 30%; }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%); }
    .gotop {
      display: flex;
      align-items: flex-end;
      transform-origin: center center;
      transform: rotate(-90deg);
      position: absolute;
      bottom: calc(100% + 40px);
      right: -10px;
      font-size: 14px;
      @include clr($blue);
      &:after {
        content: "";
        display: block;
        margin-bottom: 5px;
        margin-left: 10px;
        @include size(30px,10px);
        background-image: url('/wp-content/uploads/arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center; } } }



  .widget_nav_menu,
  .widget_nav_menu+.seese-widget {
    @include container; }

  .flex {
    align-items: center;
    justify-content: space-between;
    @include container {
      display: flex; }
    @trim( tablet{
 flex-direction: column;}}

.contact{
 padding: 40px 0;
 background-image: url(/wp-content/uploads/26875845-professional-kitchen-in-modern-building.jpg));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px -400px;
    position: relative;
    @include tablet {
      background-position: center center; }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      background-color: rgba($blue,.6); }
    .flex {
      position: relative;
      z-index: 2; }
    a.logo {
      display: block;
      width: 130px;
      @include tablet {
        margin-bottom: 20px; }
      img {
        display: block;
        @include size(100%,auto); } }
    a.more {
      @include more; }
    ul {
      width: 30%;
      padding-left: 5%;
      @include tablet {
        width: 100%;
        padding: 0;
        margin-bottom: 20px; }
      li {
        display: flex;
        align-items: center;
        @include tablet {
          justify-content: center; } }
      li,a,span {
        line-height: 28px;
        white-space: nowrap;
        @include clr(#fff);
        @include font(16px,1.6px,#fff);
        @include desk {
          @include font(14px,1.3px,#fff); } } } }



  .widget_nav_menu {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
      align-items: center; }
    .clear {
      display: none; }
    .links {
      ul {
        li {
          padding: 0;
          margin-left: 15px;
          padding-left: 15px;
          border-left: solid 1px #fff;
          &:first-of-type {
            border: none;
            padding-left: 0;
            margin-left: 0; }
          &:after {
            display: none; }
          a {
            @include size(25px);
            img {
              display: block;
              @include size(100%,auto); } } } } } }

  .main-navigation {
    display: flex;
    margin-bottom: 20px;
    @include tablet {
      display: block;
      width: 100%;
      max-width: 400px; }
    @include phone {
      max-width: 100%; }
    ul {
      display: none;
      li {
        a {
          display: block;
          padding: 5px 0;
          @include font(16px,1px,$blue);
          @include desk {
            font-size: 14px; }
          @include tablet {
            font-size: 12px; } } }
      @include min(767px) {
        padding: 10px;
        position: absolute;
        width: 160px;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        z-index: 10;
        li {
          a {
            &:hover {
              @include clr($blue);
              text-decoration: underline!important; } } } }
      @include tablet {
        padding: 10px 15px;
        background-color: #fff;
        li {
          margin: 0;
          a {
            padding: 5px 0;
            text-align: left;
            &:hover {
              @include clr($blue); } } } } }
    li {
      display: block;
      padding: 0;
      a {
        @include font(18px,1.35px,#fff);
        @include desk {
          font-size: 16px; }
        @include tablet {
          font-size: 14px; }
        @include phone {
          font-size: 12px; } }
      &:after {
        display: none; } }
    &>li {
      margin-right: 40px;
      @include desk {
        margin-right: 15px; }
      @include tablet {
        margin-right: 0;
        margin-bottom: 10px;
        border-bottom: solid 1px rgba(#fff,.2);
        &>a {
          padding-bottom: 10px;
          font-size: 16px;
          @include phone {
            font-size: 14px; } }
        a {
          display: flex;
          justify-content: space-between;
          align-items: center; } }
      &:last-of-type {
        margin-right: 0; } }
    li.menu-item-has-children {
      @include min(767px) {
        &:hover {
          ul {
            display: block; } } }
      @include tablet {
        &>a {
          &:after {
            content: "+"; } }
        &.active {
          &>a:after {
            content: "-"; } } } } } }
