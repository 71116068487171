// 引入
@import "./_include/_include.sass";

@mixin idx-container {
  padding: 60px 0;
  @include phone {
    padding: 30px 0; } }

.kc_text_block {
  h2 {
    margin: 0 0 40px;
    padding-left: 35px;
    position: relative;
    @include phone {
      margin-bottom: 20px; }
    &:before {
      content: "";
      display: block;
      @include size(25px,1px);
      background-color: $black;
      position: absolute;
      left: 0;
      top: 30px; }
    span {
      display: flex;
      line-height: 30px;
      align-items: flex-end;
      @include font(24px,2.4px,$black) {
        font-weight: bold; }
      font {
        line-height: 46px;
        @include font(46px,3.6px,$blue) {
          font-weight: bold; } }
      &+span {
        margin-top: 5px;
        @include font(18px,1.8px,#505050) {
          font-weight: bold; } } } } }

#idx-carousel {
  background-color: #F0F0F0;
  .swiper-container {
    @include tablet {
 } }      // padding-bottom: 50px
  .swiper-slide {
    padding-left: 20vw;
    position: relative;
    img {
      display: block;
      position: relative;
      z-index: 1;
      @include size(100%,auto); }
    &:after {
      content: "";
      display: block;
      @include size(30vw,0);
      padding-bottom: 30vw;
      background: $gradient;
      position: absolute;
      bottom: 50%;
      left: 0;
      transform: translateY(50%); }
    .content {
      position: absolute;
      left: 6vw;
      bottom: 50%;
      z-index: 2;
      transform: translateY(50%);
      @include tablet {
        left: 30px; }
      h3 {
        margin: 0;
        font-size: 50px;
        letter-spacing: 10px;
        line-height: 72px;
        font-weight: bold;
        white-space: nowrap;
        @include clr(#fff);
        @include tablet {
          font-size: 30px;
          line-height: 50px;
          letter-spacing: 5px; }
        @include phone {
          font-size: 20px;
          line-height: 40px; } } } }

  .swiper-pagination {
    width: auto;
    bottom: 10px;
    left: calc(20vw - 100px);
    @include tablet {
      display: flex;
      left: auto;
      right: 30px; }
    &>span {
      display: flex;
      align-items: center;
      margin: 0;
      opacity: 1;
      @include size(auto);
      font-size: 16px;
      line-height: 28px;
      background: none;
      @include clr(#969696);
      @include tablet {
        flex-direction: column-reverse; }

      .line {
        display: block;
        margin-right: 10px;
        @include size(60px,1px);
        @include t(.5);
        background-color: #DCDCDC;
        position: relative;
        overflow: hidden;
        opacity: 0;
        @include tablet {
          margin-right: 0;
          @include size(20px,1px); }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 100%;
          @include size(100%);
          @include t(5);
          background-color: #759FBD; } }

      &.swiper-pagination-bullet-active {
        @include clr($darkBlue-2);
        .line {
          opacity: 1;
          &:after {
            right: 0; } } } } } }

#idx-about {
  background-color: #F0F0F0;
  @include idx-container;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    @include size(20vw,100%);
    background-color: #fff;
    background-image: url('/wp-content/uploads/line.jpg');
    background-size: cover;
    background-position: right top; }
  &>div {
    @include container; }
  img {
    display: block;
    @include size(100%,auto); }
  .kc_text_block {
    padding-right: 50px;
    @include tablet {
      padding-right: 0;
      margin-bottom: 20px; }
    p {
      @include font;
      @include tablet {
        font-size: 16px; }
      @include phone {
        font-size: 14px; }
      @include mini {
        font-size: 12px; } }
    a {
      display: table;
      @include more;
      text-transform: uppercase;
      margin-top: 20px; } } }

// products
#idx-products {
  @include idx-container;
  &>div {
    @include container;
    &>.kc-wrap-columns {
      margin-left: -14px;
      width: calc(100% + 28px); } }

  .kc_text_block {
    padding: 0 14px; }
  .kc_row.kc_row_inner {
    img {
      display: block;
      margin: 0;
      @include size(100%,auto); }
    span {
      display: block;
      margin-bottom: 10px;
      margin-top: -15px;
      font-size: 22px;
      @include clr($green);
      font-weight: bold;
      @include tablet {
        margin-bottom: 5px; } }
    p {
      position: relative;
      padding: 0;
      a {
        display: block;
        text-align: center;
        @include font {
          font-weight: bold; }
        @include tablet {
          font-size: 16px; }
        @include phone {
          font-size: 14px; } } }
    &>div {
      @include t(.5);
      @include tablet {
        width: calc(50%);
        margin-bottom: 20px; }
      &:hover {
        transform: scale(1.1);
        p {
          a {
            @include clr($blue); } } } } } }

#idx-works {
  background-color: #F0F0F0;
  @include idx-container;
  .kc_text_block {
    @include container;
    float: none; }
  .list-post {
    visibility: hidden; }
  .swiper-slide {
    width: calc(100% / 2);
    margin: 0 30px;
    position: relative;
    @include tablet {
      width: calc(100% / 1.5);
      margin: 0 10px; }
    .info {
      display: flex;
      align-items: flex-start;
      padding: 20px 30px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba(#FFFFFF,.85);
      pointer-events: none;
      @include phone {
        pointer-events: auto;
        position: relative;
        padding: 20px; }
      .cate-time {
        display: flex;
        flex-direction: column;
        width: 130px;
        @include phone {
          width: 100px; }
        span {
          line-height: 22px;
          @include font(16px,1.6px);
          @include phone {
            font-size: 12px;
            letter-spacing: 1.2px; }
          &:first-of-type {
            @include clr($darkBlue-2);
            font-weight: bold; } } }
      .content {
        width: calc(100% - 130px);
        @include phone {
          width: calc(100% - 100px); }
        h3 {
          margin: 0 0 7px;
          @include font(16px,1.6px) {
            font-weight: bold; }
          @include tablet {
            margin-bottom: 10px; } }
        .text {
          @include font(14px,1.6px);
          line-height: 20px; } } }
    @include min(1400px) {
      width: calc(100% / 3.8); }
    img,a,figure {
      display: block;
      @include size(100%,auto); } }

  .swiper-button-next, .swiper-button-prev {
    padding-bottom: 3px;
    background: none;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left bottom;
    @include size(75px,auto);
    top: 12%;
    @include phone {
      @include size(50px,auto); }
    &:after {
      display: block;
      @include font(14px,1.4px,$blue) {
        font-weight: bold; }
      @include phone {
        font-size: 12px;
        letter-spacing: 0; } } }
  .swiper-button-next {
    right: 60px;
    background-image: url('/wp-content/uploads/arrow.svg');
    @include min(1281px) {
      right: 10%; }
    @include phone {
      right: 30px; }
    &:after {
      content: "NEXT"; } }
  .swiper-button-prev {
    left: auto;
    right: 185px;
    background-image: url('/wp-content/uploads/arrow-left.svg');
    @include min(1281px) {
      right: calc(10% + 125px); }
    @include phone {
      right: calc(10% + 60px); }
    &:after {
      content: "PREV";
      float: right; } }

  a.more {
    display: table;
    margin: 50px auto 0;
    @include more {
      background-color: transparent; } } }

// 首頁最新消息
#idx-news {
  @include idx-container;
  position: relative;
  &>div {
    @include container; }

  a.more {
    right: 15px;
    top: 30px;
    white-space: nowrap;
    display: table;
    @include more {
      position: absolute;
      @include tablet {
        position: relative;
        top: 0;
        right: 0;
        margin: 30px auto 0; } } }

  .list-post {
    visibility: hidden; }

  .kc_text_block {
    float: none; }

  .news-row {
    display: flex;
    margin-left: -8px;
    width: calc(100% + 16px);
    @include tablet {
      display: block;
      margin-left: 0;
      width: 100%; }
    .item {
      margin: 0 8px;
      width: calc(100% / 3 - 16.5px);
      position: relative;
      background: #F0F0F0;
      @include tablet {
        width: 100%;
        margin: 0 0 15px;
        &:last-of-type {
          margin-bottom: 0; } }
      &:before {
        content: "";
        display: block;
        @include size(100%);
        background: $gradient;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        @include t(.5); }
      a {
        display: block;
        padding: 50px 35px 30px;
        position: relative;
        z-index: 2;
        .cate {
          padding: 3px 30px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $blue;
          @include font(12px,1.2px,#fff);
          @include t(.5); }
        .content {
          span,h3 {
            display: block;
            margin: 0;
            @include font(14px,1.4px,$black);
            line-height: 28px;
            @include t(.5);
            @include mini {
              font-size: 12px;
              letter-spacing: 1.2px;
              line-height: 20px; } }
          span {
            margin-bottom: 15px;
            @include tablet {
              margin-bottom: 10px; } }
          h3 {
            font-weight: bold; } } }
      &:hover {
        &:before {
          opacity: 1; }
        .cate {
          background-color: $black; }
        .content {
          span,h3 {
            @include clr(#fff); } } } } } }

.float-right-icons {
  position: fixed;
  top: 40%;
  right: 5px;
  z-index: 100;
  a {
    display: block;
    @include size(48px);
    @include phone {
      @include size(30px); }
    img {
      display: block;
      width: 100%; } } }
