// 引入
@import "./_include/_include.sass";


body {
  background: transparent; }

html,body {
  height: auto; }


.container.seese-reduced {
  padding: 0;
  max-width: 100%;
  width: 100%; }

.seese-containerWrap .seese-container-inner {
  padding-bottom: 0; }

.seese-wrapper {
  overflow: hidden; }


//header fixed
.seese-fixed-header .seese-wrapper {
  margin-top: 0; }

.seese-fixed-header .seese-header {
  position: relative; }

.container {
  margin: 0 auto; }

.row {
  margin: 0; }

[class*="col-"] {
  padding: 0; }

.container {
  padding: 0; }

.kc-container {
  padding: 0; }

.kc_row.kc_row_inner {
  width: 100%; }

.kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner {
  width: 100%; }

.kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner {
  margin: 0; }
