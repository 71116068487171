// 引入
@import "./_include/_include.sass";

.owl-nav-arrow.owl-theme .owl-controls .owl-buttons div {
  top: calc(50% - 10px);
  opacity: 1;
  left: 15px;
  width: auto;
  height: auto;
  margin: 0;
  font-size: 0;
  &:before {
    content: "";
    @include size(0);
    line-height: 0;
    font-size: 0;
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
    border-right: solid 18px $blue; } }


.owl-nav-arrow.owl-theme .owl-controls .owl-buttons .owl-next {
  right: 15px;
  &:before {
    content: "";
    border-left: solid 18px $blue;
    border-right: none; } }

.owl-theme .owl-controls .owl-buttons div {
  opacity: 1;
  &:before {} }

body:not(.home) {
  .kc_text_block {
    *+h4 {
      margin-top: 10px; }
    h3 {
      margin-bottom: 20px;
      font-size: 30px;
      letter-spacing: 2px;
      color: $darkBlue;
      line-height: normal;
      @include tablet {
        font-size: 25px; }
      @include phone {
        font-size: 22px; }
      @include mini {
        font-size: 20px; } }
    h4 {
      margin-bottom: 15px;
      color: $black;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 28px;
      font-size: 20px;
      @include tablet {
        margin-bottom: 10px;
        font-size: 18px; }
      @include phone {
        font-size: 16px; } }
    p {
      font-size: 18px;
      color: $black;
      line-height: 28px;
      @include tablet {
        font-size: 16px; }
      @include phone {
        font-size: 14px; }
      @include mini {
        font-size: 12px;
        line-height: 25px; } } } }

[id^="banner"] {
  .kc_column, .kc_column_inner {
    padding-left: 20vw;
    overflow: hidden;
    @include tablet {
      padding-left: 15vw; }
    img {
      display: block;
      position: relative;
      z-index: 1;
      min-width: 100%;
      min-height: 350px;
      object-fit: cover;
      @include tablet {
        min-height: 250px; } }

    .kc_text_block {
      position: absolute;
      z-index: 2;
      padding-top: 80px;
      padding-bottom: 80px;
      padding-left: 13vw;
      padding-right: 2vw;
      width: 30vw;
      min-width: 320px;
      left: 0;
      bottom: 50%;
      transform: translateY(50%);
      background: $gradient;
      @include tablet {
        min-width: 260px;
        padding-top: 40px;
        padding-bottom: 40px; }
      h3,h4 {
        margin: 0;
        color: #fff;
        display: flex;
        align-items: flex-end; }
      h3 {
        font-size: 18px;
        letter-spacing: 1.8px;
        padding-left: 25px;
        @include tablet {
          font-size: 16px;
          padding-left: 20px; } }
      h4 {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 24px;
        position: relative;
        @include tablet {
          font-size: 20px; }
        &:before {
          content: "";
          display: block;
          position: absolute;
          right: 100%;
          margin-right: 10px;
          bottom: calc(43% - 1px);
          @include size(30px,1px);
          background-color: #fff; }
        strong {
          margin-right: 5px;
          font-size: 36px;
          line-height: 34px;
          @include tablet {
            font-size: 30px;
            line-height: 30px;
            margin-right: 2px; } } } } } }

[id^="module-product"] {
  padding: 60px 0;
  @include phone {
    padding-top: 40px; }
  &>div {
    @include container2; }

  .kc_row.kc_row_inner {
    padding: 20px 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    &>div[class*="col-"] {
      padding: 0 20px;
      @include tablet {
        margin-bottom: 30px; } } }

  .kc_text_block {
    ul {
      overflow: auto;
      &+p {
        padding-top: 10px;
        font-size: 14px;
        @include clearfix;
        @include phone {
          font-size: 12px; }
        a {
          display: block;
          width: fit-content;
          padding: 3px 40px 2px 20px;
          float: right;
          border: solid 1px $blue;
          border-radius: 3px;
          letter-spacing: 1px;
          color: $darkBlue;
          background-image: url(/wp-content/uploads/icon_download.svg);
          background-repeat: no-repeat;
          background-size: 9px auto;
          background-position: 85% center; } }
      li {
        min-width: 1024px;
        margin: 0;
        list-style-type: none;
        display: flex;
        letter-spacing: 1px;
        line-height: normal;
        color: #282828;
        font-size: 0;
        strong {
          padding: 10px 20px;
          font-size: 14px;
          font-weight: normal;
          @include mini {
            font-size: 12px; }
          &:first-of-type {
            font-weight: bold; }
          &:nth-of-type(1) {
            width: 15%; }
          &:nth-of-type(2) {
            width: 25%; }
          &:nth-of-type(3) {
            width: 10%; }
          &:nth-of-type(4) {
            width: 15%; }
          &:nth-of-type(5) {
            width: 20%; }
          &:nth-of-type(6) {
            width: 15%; } }
        &:nth-of-type(2n+1) {
          background-color: #E0E0E0; }
        &:nth-of-type(2n+2) {
          background-color: #F0F0F0; }
        &:first-of-type {
          background-color: $blue;
          color: #fff;
          strong {
            font-weight: bold; } } } }
    p {
      em {
        display: none;
        line-height: inherit;
        font-style: normal;
        vertical-align: bottom;
        &.active {
          display: inline;
          animation: fade 1s both; } }

      a[href="#read-more"] {
        color: $darkBlue;
        &:after {
          content: "";
          display: inline-block;
          margin-bottom: 3px;
          margin-left: 5px;
          @include size(0);
          border-top: solid 9px $darkBlue;
          border-left: solid 5px transparent;
          border-right: solid 5px transparent; }
        &.active:after {
          transform: rotate(180deg); } } } }

  .kc_text_block.sop {
    position: relative;
    padding: 100px 0 200px;
    margin: 0;
    @include tablet {
      padding: 30px 0 50px;
      margin-top: 20px; }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 50%;
      left: 0;
      @include size(calc(100% - 10px),3px);
      transform: translateY(50%);
      background-color: $darkBlue;
      @include tablet {
        bottom: auto;
        top: 0;
        transform: none;
        @include size(3px,100%); } }
    &:before {
      content: "";
      display: block;
      @include size(0);
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: translateY(50%);
      border-left: solid 17px $darkBlue;
      border-bottom: solid 10px transparent;
      border-top: solid 10px transparent;
      @include tablet {
        display: none; } }
    h5 {
      position: absolute;
      margin: 0;
      @include size(0);
      white-space: nowrap;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      cursor: pointer;
      @include tablet {
        position: relative;
        margin-bottom: 30px;
        @include size(100%,auto);
        justify-content: flex-start;
        padding-left: 30px; }
      img {
        margin: 0;
        position: absolute;
        @include size(200px,auto);
        left: -100px;
        max-width: fit-content;
        z-index: 2;
        opacity: 0;
        transform: scale(0);
        @include t(.5);
        @include tablet {
          @include size(50%,auto);
          position: static;
          transform: none;
          opacity: 1;
          margin-right: 30px; } }
      &:hover {
        z-index: 3;
        img {
          display: block;
          opacity: 1;
          transform: scale(1); } }

      strong {
        padding: 8px 20px;
        background-color: $blue;
        color: #fff;
        @include tablet {
          width: calc(50% - 30px); } }

      &:nth-of-type(odd) {
        top: calc(50% - 50px);
        img {
          top: -45px; }
        &:after {
          content: "";
          display: block;
          @include size(22px);
          background-color: $blue;
          position: absolute;
          top: 39px;
          @include tablet {
            left: auto!important;
            right: calc(100% - 12px);
            z-index: 2;
            top: calc(50% - 11px); } } }

      &:nth-of-type(even) {
        bottom: calc(50% - 50px);
        img {
          bottom: -55px; }
        &:after {
          content: "";
          display: block;
          @include size(22px);
          background-color: $blue;
          position: absolute;
          bottom: 39px;
          @include tablet {
            right: calc(100% - 12px);
            top: calc(50% - 11px); } } }

      &:nth-of-type(1) {
        left: 10%;
        @include tablet {
          left: 0; } }
      &:nth-of-type(2) {
        left: 25%;
        @include tablet {
          left: 0; } }
      &:nth-of-type(3) {
        left: 40%;
        @include tablet {
          left: 0; } }
      &:nth-of-type(4) {
        left: 55%;
        @include tablet {
          left: 0; } }
      &:nth-of-type(5) {
        left: 70%;
        @include tablet {
          left: 0; } }
      &:nth-of-type(6) {
        left: 85%;
        @include tablet {
          left: 0; } } } }

  .owl-item .item a {
    display: block;
    position: relative;
    color: #fff;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      background-color: rgba(#000,.4);
      padding: 0;
      margin: 0;
      @include font(20px,#fff,0);
      font-weight: bold;
      line-height: normal;
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      @include t(.5);
      opacity: 0; }
    &:hover {
      p {
        opacity: 1; } } } }

body.archive.category {
  #banner {
    float: none; }

  .kc-container {
    max-width: 100%; }

  .wp-pagenavi {
    margin-bottom: 100px;
    margin-top: 30px;
    @include phone {
      margin-bottom: 50px;
      margin-top: 0; } }

  .seese-title-area.seese-extra-width {
    display: none; }

  .seese-containerWrap .container {
    @include container2; }

  .widget-title {
    display: none; }

  .widget_categories {
    padding: 65px 0 60px;
    @include tablet {
      padding: 0 0 30px; }
    &>ul {
      &>li {
        &:not(.current-cat):not(.current-cat-parent) {
          display: none; }
        &>a {
          display: none; }
        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          li {
            padding: 0 25px;
            @include phone {
              padding: 0 10px; }
            a {
              display: block;
              font-size: 20px;
              letter-spacing: 1.5px;
              line-height: normal;
              color: $darkBlue;
              font-weight: bold;
              @include phone {
                font-size: 16px; } } } } } } }

  .seese-container-inner {
    .row {
      display: flex;
      flex-direction: column-reverse;
      &>div {
        width: 100%; } } }


  .post {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 35px;
    @include tablet {
      flex-direction: column; }
    .seese-featureImg {
      margin: 0;
      width: 50%;
      @include tablet {
        width: 100%; }
      img {
        @include size(100%);
        object-fit: cover; } }

    .seese-blog-excerpt {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 50px 8.5%;
      width: 50%;
      background: #F0F0F0;
      @include tablet {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px; } }
    .blog-heading {
      margin-bottom: 30px;
      @include phone {
        margin-bottom: 20px; }
      a {
        font-size: 22px;
        letter-spacing: 2px;
        color: $black;
        font-weight: bold;
        line-height: normal;
        @include tablet {
          font-size: 20px; }
        @include phone {
          font-size: 18px; } } }

    .seese-article {
      color: #686868;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: normal;
      @include tablet {
        font-size: 16px; }
      @include phone {
        font-size: 14px; } }

    .seese-readmore {
      padding-top: 30px;
      @include clearfix;
      a {
        display: block;
        width: fit-content;
        float: right;
        font-size: 0;
        line-height: normal;
        letter-spacing: 1px;
        color: $blue;
        font-weight: normal;
        &:before {
          display: block;
          content: "MORE";
          font-size: 14px;
          padding-bottom: 5px;
          padding-right: 20px;
          background-image: url('/wp-content/uploads/arrow.svg');
          background-repeat: no-repeat;
          background-position: right bottom;
          background-size: 100% auto; }
        i {
          display: none; } } }

    .seese-publish {
      padding: 0;
      li {
        &.author,&.dashline {
          display: none; }
        &.date {
          margin-bottom: 10px;
          color: #686868;
          font-size: 18px;
          letter-spacing: 1px;
          font-weight: bold;
          font-style: normal;
          font-family: 'microsoft jhenghei';
          @include tablet {
            font-size: 16px; }
          @include phone {
            font-size: 14px; } }
        &.cat {
          span {
            display: none; }
          a:first-of-type {
            display: none; }
          a:last-of-type {
            padding: 8px 20px;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 18px;
            letter-spacing: 1px;
            background-color: $blue;
            color: #fff;
            font-weight: normal;
            @include tablet {
              font-size: 16px; }
            @include phone {
              padding: 5px 10px;
              font-size: 14px; } } } } } } }

.wp-pagenavi,.pagecounts.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      margin: 0 5px;
      list-style-type: none;
      cursor: pointer; } } }

.pagecounts.btns {
  padding-top: 20px; }

.page-numbers a, .page-numbers span.dots, .page-numbers span.current, .wp-pagenavi span.current, .wp-pagenavi a,
.pagecounts.btns ul li,.pagecounts.btns .motion {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: $darkBlue;
  font-size: 18px;
  padding-left: 7px;
  padding-right: 7px;
  @include size(35px);
  padding: 0;
  cursor: pointer; }

.wp-link-pages a:hover, .wp-link-pages>span, .page-numbers a:hover, .page-numbers span.current, .wp-pagenavi span.current, .wp-pagenavi a:hover,
.pagecounts.btns ul li:hover,.pagecounts.btns ul li.active {
  background-color: $darkBlue;
  color: #fff; }

body.single.single-post {
  .seese-sidebar {
    display: none; }

  .seese-contentCol {
    width: 100%; }

  .seese-content-area {
    @include container2;
    @include phone {
      width: 100%; } }


  .seese-pagination {
    border-top: solid 1px #eee;
    border-bottom: none;
    padding-top: 30px;
    margin-top: 30px;
    .row {
      display: flex;
      flex-direction: row-reverse;
      div {
        a {
          span {
            display: block;
            font-size: 18px;
            font-weight: normal;
            @include tablet {
              font-size: 16px; }
            @include phone {
              font-size: 12px; } }
          .seese-label {
            margin-bottom: 5px;
            color: $blue; } } }
      .newer {
        text-align: right; }
      .older {
        text-align: left; } } }

  .post-heading {
    padding-top: 70px;
    padding-bottom: 5px;
    text-align: left;
    font-size: 30px;
    color: $darkBlue;
    letter-spacing: 2px;
    @include tablet {
      font-size: 24px;
      letter-spacing: 1px;
      padding-top: 50px; }
    @include phone {
      font-size: 20px; }
    @include mini {
      font-size: 18px; } }

  .seese-sharebar {
    display: none; }

  .seese-featured {
    margin-bottom: 30px;
    img {
      width: 100%; } }

  .seese-publish {
    position: absolute;
    top: 40px;
    left: 0;
    ul {
      li:not(.date) {
        display: none; }
      li.date {
        font-style: normal;
        font-family: 'microsoft jhenghei';
        font-size: 18px;
        letter-spacing: 1.3px;
        font-weight: bold;
        line-height: normal;
        color: #686868;
        @include phone {
          font-size: 12px; } } } } }

[id^="module-location"] {
  padding: 60px 0;
  @include phone {
    padding-top: 40px; }
  &>div {
    @include container2;
    .kc_row_inner {
      max-width: 900px;
      margin: 0 auto 50px;
      @include clearfix;
      float: none;
      @include tablet {
        width: 100%; } }
    .kc_text_block {
      @include tablet {
        margin-bottom: 20px; }
      h3 {
        font-size: 20px;
        letter-spacing: normal; }
      p {
        padding: 0;
        vertical-align: bottom;
        a {
          color: $black;
          vertical-align: bottom; } } } }

  .kc-google-maps {
    padding-bottom: 60%;
    position: relative;
    iframe {
      position: absolute;
      @include size(100%); } } }

[id^="module-howto"] {
  padding: 60px 0;
  @include phone {
    padding-top: 40px; }
  &>div {
    @include container2; }

  .kc_tabs>.kc_wrapper>.kc_tabs_nav,
  .kc_tabs_nav~.kc_tab.ui-tabs-body-active {
    background: none; }

  .kc_tabs>.kc_wrapper>.kc_tabs_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    @include phone {
      margin-bottom: 30px; }
    li {
      list-style-type: none;
      margin: 0 8px 10px;
      @include phone {
        margin: 0 5px 10px; }
      a {
        display: block;
        padding: 12px 28px;
        line-height: normal;
        border-radius: 3px;
        color: $blue;
        border: solid 1px $blue;
        font-size: 20px;
        font-weight: bold;
        @include tablet {
          font-size: 18px; }
        @include phone {
          font-size: 14px;
          padding: 6px 18px; }
        @include mini {
          font-size: 12px; } }
      &.ui-tabs-active,&:hover {
        a {
          color: #fff;
          background-color: $blue; } } } }


  .kc_tabs_nav~.kc_tab .kc_tab_content {
    padding: 0;
    @include clearfix;
    .kc_text_block {
      padding: 70px 15%;
      background-color: #F0F0F0;
      height: 100%;
      @include tablet {
        padding: 50px; }
      p {
        a {
          display: block;
          width: fit-content;
          padding: 8px 30px;
          border: solid 2px $darkBlue;
          color: $darkBlue;
          border-radius: 3px;
          font-weight: bold; } } } } }


[id^="module-history"] {
  padding: 60px 0 100px;
  @include phone {
    padding-top: 40px;
    padding-bottom: 60px; }
  &>div {
    @include container2; }
  .kc_text_block {
    display: block;
    float: none;
    max-width: 768px;
    margin: 0 auto;
    border-left: 6px $darkBlue solid;
    background-color: #F0F0F0;
    p {
      width: 100%;
      display: flex;
      padding-bottom: 15px;
      padding-top: 15px;
      padding-right: 15px;
      br {
        // display: none
        &:first-of-type {
          display: none; } }
      strong {
        display: block;
        min-width: 120px;
        padding-right: 10px;
        margin-right: 20px;
        padding-left: 10px;
        padding-top: 15px;
        margin-top: -15px;
        margin-bottom: -15px;
        font-weight: normal;
        background-color: $blue;
        color: #fff;
        @include phone {
          min-width: 90px; } } } } }

[id^="module-service"] {
  padding: 60px 0 100px;
  @include phone {
    padding-top: 40px;
    padding-bottom: 60px; }
  &>div {
    @include container2; }
  .kc_row_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    width: calc(100% + 30px);
    &>div {
      padding: 0 15px;
      margin-bottom: 30px;
      .kc_wrapper {
        position: relative; } }

    .kc_text_block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      padding: 30px;
      top: 0;
      left: 0;
      @include size(100%);
      background-color: rgba(#000,.5);
      img {
        margin: 0 auto;
        @include size(100px,auto);
        @include max(1200px) {
          @include size(50px,auto); } }
      h5 {
        margin-bottom: 30px;
        @include max(1200px) {
          margin-bottom: 15px; } }
      h4 {
        color: #fff;
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 0px;
        letter-spacing: 1.5px;
        line-height: normal;
        @include max(900px) {
          font-size: 16px; }
        &:after {
          content: "";
          display: none;
          @include size(30px,1px);
          margin-top: 15px;
          background-color: #fff;
          margin: 15px auto;
          @include tablet {
            display: block; } }
        &+p,&+p+p {
          display: none;
          @include tablet {
            display: block; } } }
      p {
        margin: 0 auto;
        color: #fff;
        font-size: 20px;
        max-width: 260px;
        line-height: normal;
        @include max(1200px) {
          font-size: 16px; }
        @include max(900px) {
          font-size: 14px; }
        a {
          display: block;
          margin-top: 20px;
          width: fit-content;
          padding: 10px 30px;
          border: solid 2px #fff;
          color: #fff;
          font-weight: bold;
          border-radius: 3px;
          @include max(1200px) {
            margin-top: 0px;
            padding: 5px 15px; }
          &:hover {
            background-color: #fff;
            color: $black; } } }
      &:hover {
        h4 {
          &:after {
            display: block; }
          &+p,&+p+p {
            display: block; } } } } } }

[id^="module-works"] {
  padding: 60px 0 100px;
  @include phone {
    padding-top: 40px;
    padding-bottom: 60px; }
  &>div {
    @include container2; }

  .kc_row_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    width: calc(100% + 20px);
    &>div {
      padding: 0 10px;
      margin-bottom: 20px; }
    .kc_text_block {
      h5 {
        display: flex;
        padding-top: 10px;
        margin-bottom: 0;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: normal;
        color: #323232;
        @include max(1000px) {
          font-size: 14px; }
        em {
          font-style: normal;
          display: block;
          color: $blue;
          min-width: 75px;
          line-height: normal; } } } }

  .kc_image_gallery {
    .item-grid {
      float: none;
      width: 100%;
      &:not(:first-child) {
        display: none; } } } }

[id^="module-partner"] {
  padding: 60px 0 100px;
  @include phone {
    padding-top: 40px;
    padding-bottom: 60px; }
  &>div {
    @include container2; }

  .kc_row_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    width: calc(100% + 30px);
    &>div {
      padding: 0 15px;
      margin-bottom: 30px; }
    .kc_text_block {
      h5 {
        padding: 40px 0;
        img {
          display: block;
          margin: 0;
          width: 100%; } }

      p {
        padding: 8px 20px;
        background-color: $blue;
        color: #fff;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: normal;
        text-align: center;
        @include tablet {
          font-size: 16px; }
        @include phone {
          font-size: 14px; }
        a {
          color: #fff; } } } } }

[id^="module-company"] {
  padding: 60px 0 100px;
  @include phone {
    padding-top: 40px;
    padding-bottom: 60px; }
  &>div {
    @include container2; }

  .kc_row_inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 40px 0 10px;
    margin-left: -15px;
    width: calc(100% + 30px);
    margin-bottom: 40px;
    position: relative;
    @include tablet {
      padding-bottom: 0; }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      @include size(100%);
      background-color: #F0F0F0; }
    &:nth-of-type(odd) {
      flex-direction: row-reverse;
      &:after {
        right: 20%; } }
    &:nth-of-type(even) {
      &:after {
        left: 20%; } }

    &>div {
      padding: 0 15px;
      margin-bottom: 30px;
      z-index: 2; } } }
