// color
$black: #323232;
$gray: #787878;
$gray-2: #969696;
$green: #01B468;
$blue: #649CD3;
$darkBlue: #144957;
$darkBlue-2: #56718F;

// linear
$gradient: transparent linear-gradient(90deg, #649CD3 0%, #07A85D 100%) 0% 0% no-repeat padding-box;

@mixin desk($desk:1024px) {
  @media screen and (max-width: $desk) {
    @content; } }

@mixin tablet($tablet:767px) {
  @media screen and (max-width: $tablet) {
    @content; } }

@mixin phone($phone:640px) {
  @media screen and (max-width: $phone) {
    @content; } }

@mixin mini($min:500px) {
  @media screen and (max-width: $min) {
    @content; } }

@mixin max($size) {
  @media screen and (max-width: $size) {
    @content; } }

@mixin min($size) {
  @media screen and (min-width: $size) {
    @content; } }

//mixin
@mixin size($w,$h:$w) {
  width: $w;
  height: $h; }

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin t($t:0.5) {
  transition-duration: #{$t}s; }

@mixin clr($c:#222) {
  color: $c; }

@mixin container {
  display: block;
  margin: 0 auto;
  padding: 0;
  max-width: calc(1280px - 120px);
  width: calc(100% - 60px);
  @include min(1500px) {
    max-width: 1500px; }
  @content; }

@mixin container2 {
  display: block;
  margin: 0 auto;
  padding: 0;
  max-width: calc(1280px - 120px);
  width: calc(100% - 20vw);
  @include min(1500px) {
    max-width: 1500px; }
  @include phone {
    width: calc(100% - 60px); }
  @content; }

// font
@mixin font($size:18px,$ls:1.35px,$clr:$black) {
  font-size: $size;
  letter-spacing: $ls;
  color: $clr;
  font-weight: normal;
  @content; }

// more
@mixin more($bgc:#fff) {
  display: table;
  @include font(14px,1.4px,$darkBlue);
  border-radius: 3px;
  padding: 8px 40px 8px 25px;
  border: solid 1px $blue;
  background: $bgc;
  position: relative;
  &:after {
    content: "";
    display: block;
    @include size(26px,6px);
    background-image: url('/wp-content/uploads/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: absolute;
    left: calc(100% - 13px);
    bottom: calc(50%); }
  @content;
  &:hover {
    @include clr(#fff);
    background: $gradient; } }


@mixin module_hide {
  &>.kc-row-container.kc-container {
    visibility: hidden; } }

